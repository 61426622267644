/**
 * Brandfolder API model
 */
import { curry, identity, pickBy } from 'ramda';

export const SUPPORTED_IMAGE_EXTENSIONS = ['ai', 'jpg', 'jpeg', 'png', 'svg'];

export enum ResourceType {
  ATTACHMENT = 'attachments',
  ASSET = 'assets',
  BRANDFOLDER = 'brandfolders',
  COLLECTION = 'collections',
  CUSTOM_FIELD_KEY = 'custom_field_keys',
  CUSTOM_FIELD_VALUE = 'custom_field_values',
  CUSTOM_FIELD_VALUE_OPTION = 'custom_field_value_option',
  GENERIC_FILE = 'generic_files',
  LABEL = 'labels',
  ORGANIZATION = 'organizations',
  PERMISSION_LEVEl = 'permission_levels',
  SEARCH_FILTER = 'search_filters',
  SECTION = 'sections',
  SHARE_MANIFEST = 'share_manifests',
  TAG = 'tags',
  TEXT = 'texts',
  USER = 'users',
}

export type AssetType = ResourceType.GENERIC_FILE | ResourceType.TEXT;

export enum Relationship {
  ASSET = 'asset',
  ASSETS = 'assets',
  ATTACHMENTS = 'attachments',
  BRANDFOLDER = 'brandfolder',
  BRANDFOLDERS = 'brandfolders',
  COLLECTIONS = 'collections',
  CUSTOM_FIELDS = 'custom_fields',
  ORGANIZATION = 'organization',
  SECTION = 'section',
  SECTIONS = 'sections',
  TAGS = 'tags',
}

export interface RelationshipResourceDto<T extends ResourceType = ResourceType> {
  id: string;
  type: T;
}

export interface ManyToOneRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T;
}

export interface OneToManyRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T[];
}

export interface ManyToManyRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T[];
}

export interface ResourceDtoBase {
  id?: string;
  type: ResourceType;
}

export interface ResourceAttributesDto {
  name: string;
  slug: string;
  position: number;
  created_at?: string;
  updated_at?: string;
  default_asset_type?: string;
}

export interface AssetDto extends ResourceDtoBase {
  type: AssetType;
  attributes: ResourceAttributesDto & {
    attachment_count?: number;
    thumbnail_url?: string;
    description?: string;
    availability_end: Date;
    availability: string;
    extension: string;
  };
  relationships: {
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.SECTION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.SECTION>
    >;
    [Relationship.ATTACHMENTS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.ATTACHMENT>
    >;
    [Relationship.CUSTOM_FIELDS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.CUSTOM_FIELD_VALUE>
    >;
  };
}

export interface AssetIdsDto extends ResourceDtoBase {
  ids: string[];
}

export interface AssetCountDto extends ResourceDtoBase {
  count: number;
}

export interface CollectionStorageDto extends ResourceDtoBase {
  remaining: number;
}

export interface AttachmentDto extends ResourceDtoBase {
  type: ResourceType.ATTACHMENT;
  attributes: ResourceAttributesDto & {
    thumbnail_url?: string;
    cdn_url?: string;
    url: string;
    filename: string;
    extension: string;
    is_processing: boolean;
    mimetype: string;
    width: number;
    height: number;
    size: number;
    view_only?: boolean;
  };
  relationships: {
    [Relationship.ASSET]: ManyToOneRelationshipDto<RelationshipResourceDto<AssetType>>;
  };
}

export interface AttachmentInputDto {
  type: ResourceType.ATTACHMENT;
  url: string;
  filename: string;
  mimetype: string;
  /**
   * https://github.com/brandfolder/boulder/blob/1e39170e90fac2e0d0ae9b4562f8223f026de803/app/models/attachment.rb#L70
   */
  source?: string;
}

export type IncludedAttachmentDto = Omit<AttachmentDto, 'relationships'>;

export function getAttachmentDtoUrl(attachmentDto: AttachmentDto): string {
  return attachmentDto.attributes.cdn_url || attachmentDto.attributes.url;
}

export interface BrandfolderDto extends ResourceDtoBase {
  type: ResourceType.BRANDFOLDER;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
    attachment_count?: number;
    card_image?: string;
    storage?: string;
  };
  relationships: {
    [Relationship.ASSETS]?: OneToManyRelationshipDto<RelationshipResourceDto<AssetType>>;
    [Relationship.COLLECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.COLLECTION>
    >;
    [Relationship.ORGANIZATION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.ORGANIZATION>
    >;
    [Relationship.SECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.SECTION>
    >;
  };
}

export type IncludedBrandfolderDto = Omit<BrandfolderDto, 'relationships'>;

export interface CollectionDto extends ResourceDtoBase {
  type: ResourceType.COLLECTION;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
    attachment_count?: number;
  };
  relationships: {
    [Relationship.ASSET]?: ManyToManyRelationshipDto<RelationshipResourceDto<AssetType>>;
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.ORGANIZATION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.ORGANIZATION>
    >;
  };
}

export interface OrganizationDto extends ResourceDtoBase {
  type: ResourceType.ORGANIZATION;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
  };
  relationships: {
    [Relationship.ASSETS]?: OneToManyRelationshipDto<RelationshipResourceDto<AssetType>>;
    [Relationship.BRANDFOLDERS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.COLLECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.COLLECTION>
    >;
  };
}

export interface SearchFilterDto extends ResourceDtoBase {
  type: ResourceType.SEARCH_FILTER;
  attributes: ResourceAttributesDto & {
    label: string;
    query: string;
    featured: boolean;
  };
}

export interface SectionDto extends ResourceDtoBase {
  type: ResourceType.SECTION;
  attributes: ResourceAttributesDto;
  relationships: {
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
  };
}

export interface UserDto extends ResourceDtoBase {
  type: ResourceType.USER;
  attributes: {
    email: string;
    first_name: string;
    last_name: string;
  };
}

export interface TagDto extends ResourceDtoBase {
  type: ResourceType.TAG;
  attributes: ResourceAttributesDto & {
    auto_generated: boolean;
  };
}

export interface CustomFieldKeyDto extends ResourceDtoBase {
  type: ResourceType.CUSTOM_FIELD_KEY;
  attributes: {
    allowed_values: string[];
    restricted: boolean;
    prioritized: boolean;
  } & ResourceAttributesDto;
}

export interface CreateCustomFieldKeyData {
  name: string;
  allowed_values?: string[];
}

export interface CustomFieldKeyResponseDatum {
  id: string;
  type: string;
  attributes: {
    allowed_values: string[];
    name: string;
    prioritized?: boolean;
    required?: boolean;
    restricted: boolean;
  };
}

export interface CustomFieldKeyResponseData {
  data: CustomFieldKeyResponseDatum[];
  meta?: {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
  };
}

export interface CustomFieldValueDto extends ResourceDtoBase {
  attributes: ResourceAttributesDto & {
    key: string;
    value: string;
  };
}

export interface CustomFieldKeyValueDto {
  [key: string]: string[];
}

export interface CustomFieldValueOptions {
  data: CustomFieldValueResponseDto;
}

export interface CustomFieldValueResponseDto {
  custom_field_values: string[];
}

export interface AddAssetTagsResponseDatum {
  id: string;
  type: string;
  attributes: {
    name: string;
    auto_generated: boolean;
  };
}
export interface AddAssetTagsResponseData {
  data: AddAssetTagsResponseDatum[];
  meta?: {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
  };
}

export interface ShareManifestDto extends ResourceDtoBase {
  attributes: ResourceAttributesDto & {
    availability_end: string;
    availability_start: string;
    name: string;
    link: string;
    view_only: boolean;
  };
}

export interface GenericFileDto extends ResourceDtoBase {
  type: ResourceType.GENERIC_FILE;
  attributes: ResourceAttributesDto;
}

export interface LabelDto extends ResourceDtoBase {
  type: ResourceType.LABEL;
  attributes: ResourceAttributesDto & {
    path: string[];
    depth: number;
  };
}

export interface BulkDownloadAssetsDto {
  data: { attachment_count: string; manifest_digest: string; redirect_url: string };
}

export interface PermissionLevelDto {
  type: ResourceType.PERMISSION_LEVEl;
  id: PermissionLevels;
}

export enum PermissionLevels {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Guest = 'guest',
  Owner = 'owner',
}

export type ResourceDto =
  | AssetDto
  | AttachmentDto
  | CustomFieldKeyDto
  | CustomFieldValueDto
  | IncludedAttachmentDto
  | BrandfolderDto
  | IncludedBrandfolderDto
  | CollectionDto
  | OrganizationDto
  | SearchFilterDto
  | SectionDto
  | TagDto
  | ShareManifestDto
  | GenericFileDto
  | LabelDto;

export function isSectionDto(resource: ResourceDto): resource is SectionDto {
  return resource.type === ResourceType.SECTION;
}

export type PaginationType = 'count' | 'countless' | 'cursor';

export type PagedResultsSortOrder = 'asc' | 'desc';

interface ApiDataResponseBase {
  included: ResourceDto[];
}

interface ApiDataResponseError {
  errors: { title: string; detail: string }[];
}

export type ApiFetchDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T;
} & ApiDataResponseBase;

export type ApiFetchDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiFetchDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiListDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T[];
  meta?: {
    total_count?: number;
    current_page?: number;
    next_page?: number;
    prev_page?: number;
    previous_page?: number;
    total_pages?: number;
    record_count?: number;
    cache_duration_seconds?: number;
    limit_reached?: boolean;
    pagination_type?: PaginationType;
    more_exist?: boolean;
    next_cursor?: string;
    next_cursor_param?: string;
    sort_order?: PagedResultsSortOrder;
    from?: number;
    to?: number;
    offset?: number;
  };
} & ApiDataResponseBase;

export type ApiListDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiListDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiIdsDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T;
  meta?: {
    record_count?: number;
  };
} & ApiDataResponseBase;

export type ApiCountDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T;
  meta?: {
    cache_duration_seconds: 60;
    limit_reached: false;
  };
} & ApiDataResponseBase;

export type ApiStorageDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T;
  meta?: {
    storage_limit: number;
  };
} & ApiDataResponseBase;

export type ApiIdsDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiIdsDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiCountDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiCountDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiStorageDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiStorageDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiSearchableAggregationsResponse = {
  custom_fields?: { [name: string]: string }[];
  filetypes: string[];
  tags: string[];
};

export type ApiDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiFetchDataResponseSuccess<T>
  | ApiListDataResponseSuccess<T>;

export type ApiDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiDataResponseSuccess<T>
  | ApiDataResponseError;

export function isError(response: ApiDataResponse): response is ApiDataResponseError | null {
  if (!response) return true;
  return (<ApiDataResponseError>response).errors?.length > 0;
}

export const getResponseData = <T extends ApiDataResponseSuccess>(response: T): T['data'] =>
  response.data;

export const getResponseDataOrDefault = <T extends ApiDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['data'] => (isError(response) ? null : response.data);

export const getResponseListData = <T extends ApiListDataResponseSuccess>(response: T): T['data'] =>
  response.data;

export const getResponseListDataOrDefault = <T extends ApiListDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['data'] => (isError(response) ? [] : getResponseListData(response));

export const getResponseIncluded = <T extends ApiDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['included'] => (isError(response) ? [] : response.included);

export function getResponseMeta<
  T extends
    | ApiListDataResponseSuccess
    | ApiIdsDataResponseSuccess
    | ApiCountDataResponseSuccess
    | ApiStorageDataResponseSuccess,
>(response: T | ApiDataResponseError): T['meta'] {
  return isError(response) ? {} : response.meta;
}

export const getRelationshipsData = curry(
  (apiResponse: ApiDataResponseSuccess, relationshipDto: RelationshipResourceDto): ResourceDto => {
    return getResponseIncluded(apiResponse).find((resource) => resource.id === relationshipDto.id);
  },
);

export enum FieldParameter {
  AssetCount = 'asset_count',
  AttachmentCount = 'attachment_count',
  Availability = 'availability',
  CardImage = 'card_image',
  CdnUrl = 'cdn_url',
  CreatedAt = 'created_at',
  IsProcessing = 'is_processing',
  ExpiresOn = 'availability_end',
  Extension = 'extension',
  MuxHlsUrl = 'mux_hls_url',
  Storage = 'storage',
  ThumbnailUrl = 'thumbnail_url',
  UpdatedAt = 'updated_at',
}

export enum SortField {
  Name = 'name',
  Position = 'position',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  Score = 'score',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export type SearchQuery = {
  query: string;
  aspect: { operator: 'OR'; filters: string[] };
  extensions: { operator: 'OR'; filters: string[] };
  tag_names: { operator: 'AND'; filters: string[] };
  created_at: { filters: string[] };
};

export interface Options<
  FP extends FieldParameter = FieldParameter,
  I extends Relationship = Relationship,
> {
  fields?: FP[];
  include?: I[];
  search?: string; // TODO: improve type safety. ~PP
  search_query?: SearchQuery;
  per?: number;
  page?: number;
  sort?: {
    field: SortField;
    direction?: SortDirection;
  };
  after?: string;
  before?: string;
  count_based_pagination?: boolean;
  queue_priority?: 'high' | 'low' | '';
}

export type AssetFieldParameter =
  | FieldParameter.AttachmentCount
  | FieldParameter.CardImage
  | FieldParameter.CdnUrl
  | FieldParameter.CreatedAt
  | FieldParameter.ExpiresOn
  | FieldParameter.Extension
  | FieldParameter.UpdatedAt
  | FieldParameter.Availability;
export type AssetIncludeParameter =
  | Relationship.BRANDFOLDER
  | Relationship.SECTION
  | Relationship.ATTACHMENTS
  | Relationship.CUSTOM_FIELDS
  | Relationship.TAGS;
export type AssetOptions = Options<AssetFieldParameter, AssetIncludeParameter>;

export type AttachmentFieldParameter =
  | FieldParameter.CdnUrl
  | FieldParameter.CreatedAt
  | FieldParameter.IsProcessing
  | FieldParameter.ThumbnailUrl
  | FieldParameter.MuxHlsUrl
  | FieldParameter.UpdatedAt;
export type AttachmentIncludeParameter = Relationship.ASSET | Relationship.TAGS;
export type AttachmentOptions = Options<AttachmentFieldParameter, AttachmentIncludeParameter>;

export type BrandfolderFieldParameter =
  | FieldParameter.AssetCount
  | FieldParameter.AttachmentCount
  | FieldParameter.CardImage
  | FieldParameter.Storage;
export type BrandfolderIncludeParameter =
  | Relationship.ASSETS
  | Relationship.ORGANIZATION
  | Relationship.SECTIONS;
export type BrandfolderOptions = Options<BrandfolderFieldParameter, BrandfolderIncludeParameter>;

export type CollectionFieldParameter = FieldParameter.AssetCount | FieldParameter.AttachmentCount;
export type CollectionIncludeParameter = Relationship.ASSETS | Relationship.BRANDFOLDER;
export type CollectionOptions = Options<CollectionFieldParameter, CollectionIncludeParameter>;
export type RemoveAssetsFromCollectionsBody = {
  data: { attributes: { asset_keys: string[] } };
};

export type SectionFieldParameter = any;
export type SectionIncludeParameter = Relationship.BRANDFOLDER;
export type SectionOptions = Options<SectionFieldParameter, SectionIncludeParameter>;

export type OrganizationFieldParameter = any;
export type OrganizationIncludeParameter = Relationship.COLLECTIONS;
export type OrganizationOptions = Options<OrganizationFieldParameter, OrganizationIncludeParameter>;

export function optionsToQueryString(options: Options): string {
  const params = {
    fields: options?.fields?.join(','),
    include: options?.include?.join(','),
    search: options?.search,
    per: options?.per || 9999, // TODO: don't send per=9999 on every API request
    page: options?.page,
    sort_by: options?.sort?.field,
    order: options?.sort?.direction,
    queue_priority: options?.queue_priority ?? 'high',
    count_based_pagination: options?.count_based_pagination,
    after: options?.after,
    before: options?.before,
  };

  return `${options ? '?' + new URLSearchParams(pickBy(identity, params)).toString() : ''}`;
}
