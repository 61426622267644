import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  // bfBaseUrl: 'https://brandfolder.com/api',
  // bfWebsiteBaseUrl: 'https://brandfolder.com',
  // bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  bfBaseUrl: 'https://bf-stage.com/api',
  bfWebsiteBaseUrl: 'https://bf-stage.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.bf-stage.com',
  hostUrl: 'https://schwindt.ngrok.io',
  oauthBaseUrl: 'https://oauth2.stage.integration.bf-squads.com',
  oauthClientId: 'stage-demo-app',
  environment: Environment.Development,
};
