import {
  ATTACHMENT_ACTION_PROVIDER_TOKEN,
  IAttachmentActionProvider,
} from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-model';
import {
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from './../../../libs/integration/ui/src/lib/features';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
} from '@integration-frontends/common/brandfolder-api';
import {
  ANALYTICS_SERVICE_TOKEN,
  AnalyticsServiceStrategy,
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
  IAnalyticsService,
  ILogger,
  IObservabilityService,
  LOGGER_TOKEN,
  OBSERVABILITY_SERVICE_TOKEN,
  ObservabilityServiceDatadogStrategy,
} from '@integration-frontends/core';
import {
  ALL_IMAGE_TYPES,
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  BRANDFOLDER_REPO_TOKEN,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  CDN_SERVICE_TOKEN,
  COLLECTION_REPO_TOKEN,
  CONTAINER_REPO_TOKEN,
  DOWNLOAD_SERVICE_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
  IAttachmentTransformService,
  IBrandfolderRepo,
  IBrandfolderWebsiteService,
  ICdnService,
  ICollectionRepo,
  IContainerRepo,
  IDownloadService,
  ILabelRepo,
  ImageType,
  IMediaTypeSupportService,
  IOrganizationRepo,
  ISearchFilterRepo,
  ISectionRepo,
  ISharingService,
  IUserSettingsRepo,
  LABEL_REPO_TOKEN,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  ORGANIZATION_REPO_TOKEN,
  SEARCH_FILTER_REPO_TOKEN,
  SECTION_REPO_TOKEN,
  SHARING_SERVICE_TOKEN,
  USER_SETTINGS_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import {
  ISelectAttachmentOptions,
  IUploadAssetsOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectActionType,
  SelectionType,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import {
  AssetRepo,
  AttachmentRepo,
  AttachmentSelectorFeaturesService,
  AttachmentTransformService,
  BrandfolderRepo,
  BrandfolderWebsiteService,
  CdnService,
  CollectionRepo,
  ContainerRepo,
  DownloadService,
  GET_API_KEY_TOKEN,
  IGetAuthToken,
  LabelRepo,
  MediaTypeSupportServiceWhitelistStrategy,
  OrganizationRepo,
  SearchFilterRepo,
  SectionRepo,
  SharingService,
  UserSettingsRepo,
} from '@integration-frontends/integration/infrastructure/isomorphic';
import { IntegrationUiModule } from '@integration-frontends/integration/ui';
import { Container } from 'inversify';
import { difference } from 'ramda';
import { ATTACHMENT_SELECTOR_FEATURES_TOKEN } from '../../../libs/integration/ui/src/lib/features';
import {
  BF_STATIC_ASSETS_BASE_URL,
  BF_WEBSITE_BASE_URL,
} from '../../integration-panel-ui/src/config';
import { IDENTITY_STORE_TOKEN, IdentityStoreMemoryStrategy, IIdentityStore } from './app/common';
import {
  getAuthTokenShopifyStrategy,
  SHOPIFY_SESSION_TOKEN_PROVIDER,
} from './app/common/auth/get-auth-token-shopify-strategy';
import { BF_BASE_URL, SHOPIFY_BACKEND_BASE_URL } from './config';
import './i18n';
import { AttachmentActionsProviderShopifyStrategy } from './app/infrastructure/attachment-actions-provider-shopify-strategy';
import { CommonShopifyModule } from './app/common/common-shopify.module';
import {
  IGetShopifyAccessToken,
  SHOPIFY_BACKEND_API_TOKEN,
  ShopifyBackendApi,
} from './app/common/shopify-backend-api';
import { AuthenticationServiceShopifyStrategy } from './app/common/auth/authentication-service-shopify-strategy';
import { BFStorage, STORAGE_TOKEN } from '@integration-frontends/core/storage';
import { customStorage } from '@integration-frontends/apps/integration-shopify/src/app/common/custom-storage';
import { ObservabilityUiModule } from '@integration-frontends/core/ui';

// TODO: use BFModule. ~PP

/**
 * App
 */
const appContainer = new Container();
appContainer.bind<ILogger>(LOGGER_TOKEN).toConstantValue(console);
appContainer
  .bind<IIdentityStore>(IDENTITY_STORE_TOKEN)
  .toConstantValue(new IdentityStoreMemoryStrategy());
appContainer
  .bind<IGetShopifyAccessToken>(SHOPIFY_SESSION_TOKEN_PROVIDER)
  .toConstantValue(getAuthTokenShopifyStrategy);
appContainer
  .bind<ShopifyBackendApi>(SHOPIFY_BACKEND_API_TOKEN)
  .toDynamicValue(() => new ShopifyBackendApi(SHOPIFY_BACKEND_BASE_URL));
appContainer.bind<BFStorage>(STORAGE_TOKEN).toConstantValue(customStorage);
DI_CONTAINER.merge(appContainer);
CommonShopifyModule.initialize();
/**
 * Auth
 */
const authContainer = new Container();
authContainer
  .bind<IAuthenticateService>(AUTHENTICATE_SERVICE_TOKEN)
  .to(AuthenticationServiceShopifyStrategy);

AuthUiModule.initialize(authContainer);

/**
 * Integration
 */
const integrationContainer = new Container();

integrationContainer
  .bind<BrandfolderApi>(BRANDFOLDER_API_TOKEN)
  .toConstantValue(
    new BrandfolderApi(BF_BASE_URL, DI_CONTAINER.get(LOGGER_TOKEN), getAuthTokenShopifyStrategy),
  );

integrationContainer
  .bind<IGetAuthToken>(GET_API_KEY_TOKEN)
  .toConstantValue(getAuthTokenShopifyStrategy);
integrationContainer.bind<IAttachmentRepo>(ATTACHMENT_REPO_TOKEN).to(AttachmentRepo);
integrationContainer
  .bind<IAttachmentTransformService>(ATTACHMENT_TRANSFORM_SERVICE_TOKEN)
  .to(AttachmentTransformService);
integrationContainer.bind<IAssetRepo>(ASSET_REPO_TOKEN).to(AssetRepo);
integrationContainer.bind<IBrandfolderRepo>(BRANDFOLDER_REPO_TOKEN).to(BrandfolderRepo);
integrationContainer.bind<ICollectionRepo>(COLLECTION_REPO_TOKEN).to(CollectionRepo);
integrationContainer.bind<ISearchFilterRepo>(SEARCH_FILTER_REPO_TOKEN).to(SearchFilterRepo);
integrationContainer.bind<ISectionRepo>(SECTION_REPO_TOKEN).to(SectionRepo);
integrationContainer.bind<IOrganizationRepo>(ORGANIZATION_REPO_TOKEN).to(OrganizationRepo);
integrationContainer.bind<ILabelRepo>(LABEL_REPO_TOKEN).to(LabelRepo);
integrationContainer.bind<IContainerRepo>(CONTAINER_REPO_TOKEN).to(ContainerRepo);
integrationContainer.bind<IUserSettingsRepo>(USER_SETTINGS_REPO_TOKEN).to(UserSettingsRepo);

//TODO: enable the following video types once Content Sync has GraphQL endpoints
// VideoType.Mp4,
// VideoType.Mov,
const SUPPORTED_MEDIA_TYPES = difference(ALL_IMAGE_TYPES, [ImageType.Ai, ImageType.svg]);
integrationContainer
  .bind<IMediaTypeSupportService>(MEDIA_TYPE_SUPPORT_SERVICE_TOKEN)
  .toConstantValue(
    new MediaTypeSupportServiceWhitelistStrategy(SUPPORTED_MEDIA_TYPES, SUPPORTED_MEDIA_TYPES),
  );
integrationContainer.bind<IDownloadService>(DOWNLOAD_SERVICE_TOKEN).to(DownloadService);
integrationContainer.bind<ISharingService>(SHARING_SERVICE_TOKEN).to(SharingService);
integrationContainer
  .bind<IBrandfolderWebsiteService>(BRANDFOLDER_WEBSITE_SERVICE_TOKEN)
  .toConstantValue(new BrandfolderWebsiteService(BF_WEBSITE_BASE_URL, BF_STATIC_ASSETS_BASE_URL));
integrationContainer
  .bind<IAttachmentSelectorFeaturesService>(ATTACHMENT_SELECTOR_FEATURES_TOKEN)
  .toConstantValue(
    new AttachmentSelectorFeaturesService([
      AttachmentSelectorFeature.PlacementOptions,
      AttachmentSelectorFeature.Responsive,
    ]),
  );
integrationContainer
  .bind<ISelectAttachmentOptions>(SELECT_ATTACHMENT_OPTIONS_TOKEN)
  .toConstantValue({
    actionType: SelectActionType.Select,
    selectionType: SelectionType.Multi,
    showAttachmentsToggleDisabled: true,
  });
integrationContainer.bind<IUploadAssetsOptions>(UPLOAD_ASSETS_OPTIONS_TOKEN).toConstantValue({
  showAddMore: false,
  showTags: false,
  showUploadAssetsButton: false,
});
integrationContainer
  .bind<IAttachmentActionProvider>(ATTACHMENT_ACTION_PROVIDER_TOKEN)
  .toConstantValue(new AttachmentActionsProviderShopifyStrategy());
integrationContainer
  .bind<ComponentResolver>(COMPONENT_RESOLVER_TOKEN)
  .toConstantValue(new ComponentResolver());
integrationContainer.bind<ICdnService>(CDN_SERVICE_TOKEN).to(CdnService);

integrationContainer
  .bind<IAnalyticsService>(ANALYTICS_SERVICE_TOKEN)
  .to(AnalyticsServiceStrategy);

IntegrationUiModule.initialize(integrationContainer);

/** Observability
 */
const observabilityContainer = new Container();
observabilityContainer
  .bind<IObservabilityService>(OBSERVABILITY_SERVICE_TOKEN)
  .to(ObservabilityServiceDatadogStrategy);

ObservabilityUiModule.initialize(observabilityContainer);
