import { injectable } from 'inversify';
import { IGetAuthToken } from '@integration-frontends/integration/infrastructure/isomorphic';
import { DI_CONTAINER } from '@integration-frontends/core';
import { SHOPIFY_SESSION_TOKEN_PROVIDER } from './auth/get-auth-token-shopify-strategy';

/**
 * Client for interfacing with our Shopify backend
 */

export const SHOPIFY_BACKEND_API_TOKEN = 'SHOPIFY_BACKEND_API';
export type IGetShopifyAccessToken = IGetAuthToken;

@injectable()
export class ShopifyBackendApi {
  private accessTokenProvider: IGetShopifyAccessToken;

  constructor(private baseUrl: string) {
    this.accessTokenProvider = DI_CONTAINER.get(SHOPIFY_SESSION_TOKEN_PROVIDER);
  }

  public async setApiKey(apiKey: string): Promise<void> {
    await this.fetchFromApi(`/set_api_key`, {
      method: 'PUT',
      body: JSON.stringify({ api_key: apiKey }),
    });
  }

  public async getAppStorageItem<T = unknown>(key: string): Promise<T> {
    const res = await this.fetchFromApi(`/get_item?key=${encodeURIComponent(key)}`, {
      method: 'GET',
    });

    return res.json();
  }

  public async setAppStorageItem(key: string, item: string): Promise<void> {
    await this.fetchFromApi('/set_item', {
      method: 'PUT',
      body: JSON.stringify({ key, item }),
    });
  }

  private async fetchFromApi(path: string, init: RequestInit = {}) {
    return fetch(`${this.baseUrl}${path}`, {
      ...init,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${await this.accessTokenProvider()}`,
        'Content-Type': 'application/json',
        ...init?.headers,
      },
    });
  }
}
