import {
  setShopifyAppState
} from '@integration-frontends/apps/integration-shopify/src/app/common/auth/get-auth-token-shopify-strategy';
import { init } from '@integration-frontends/common/app';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useEffect } from 'react';
import { dynamicActivate } from '../../../../../i18n';
import { environment } from '../../environments/environment';
import { store } from '../store';
import { extractShopifyParams } from './extract-shopify-params';

export function useShopifyInit() {
  const data = extractShopifyParams();
  const { shop, host, isAuthed } = data;
  const app = useAppBridge();

  useEffect(() => {
    setShopifyAppState(app);

    if (host) {
      if (isAuthed) {
        store.dispatch(init({ name: 'integration-shopify' }));
        dynamicActivate('en');
      } else {
        window.location.href = `${environment.contentSyncUrlShopParam}${shop}&host=${host}`;
      }
    }
  }, [host]);
}
