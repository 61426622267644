import React, { useState } from 'react';
import { ResourcePicker } from '@shopify/app-bridge-react';
import {
  Modal as SuccessAssetModal,
  Modal as SuccessPayloadModal,
  Modal as UpdateProductConfirmModal,
  Subheading,
  TextContainer,
  Thumbnail,
} from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductCanceled } from '../update-product';
import { selectAttachmentSelectors } from '@integration-frontends/integration/core/application';
import { Loader } from '@integration-frontends/common/ui';
import { environment } from 'apps/integration-shopify/src/environments/environment';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { SHOPIFY_COMMON_NAMESPACE } from '../../common/i18n/translations';
import {
  SHOPIFY_ASSETS_HAVE_BEEN_UPLOADED_KEY,
  SHOPIFY_CANCEL_UPLOAD_KEY,
  SHOPIFY_CHOOSE_NEW_BRANDFOLDER_KEY,
  SHOPIFY_CONFIRM_KEY,
  SHOPIFY_CONFIRM_UPLOAD_KEY,
  SHOPIFY_ERRORS_UPLOADING_SOME_OF_YOUR_ASSETS_KEY,
  SHOPIFY_ERROR_KEY,
  SHOPIFY_FILENAME_KEY,
  SHOPIFY_FILES_KEY,
  SHOPIFY_FILES_READY_FOR_UPLOAD_KEY,
  SHOPIFY_SELECT_PRODUCTS_KEY,
  SHOPIFY_SUCCESS_KEY,
  SHOPIFY_TO_PRODUCT_KEY,
  SHOPIFY_UPLOADING_FILE_KEY,
  SHOPIFY_UPLOAD_FILE_FROM_CURRENT_BRANDFOLDER_KEY,
  SHOPIFY_YOUR_FILE_HAS_BEEN_SUCCESSFULLY_UPLOADED_KEY,
} from '../../common/i18n/keys';
export interface UpdateProductProps {
  shop: string;
  shopId: string;
}
export interface IUpdatePayload {
  productInfo: {
    selection?: Array<{ title: string }>;
  };
  attachments: (Attachment | AttachmentVariant)[]
  shopInfo: object;
}

export interface updateResults {
  error: string;
  status: string;
  statusCode: number;
}

export interface updateProductStatus {
  status: updateResults[];
}

export interface UpdateProductMessage {
  successMessage?: boolean;
  errorMessage?: boolean;
}

const UpdateProduct = ({ shop, shopId }: UpdateProductProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(SHOPIFY_COMMON_NAMESPACE);
  const [resourcePickerOpen, setResourcePickerOpen] = useState(false);
  const [successAssetModalActive, setSuccessAssetModalActive] = useState(true);
  const [successPayloadModalActive, setSuccessPayloadModalActive] = useState(true);
  const [updateProductConfirmActive, setUpdateProductConfirmActive] = useState(false);
  const [updateProductStatus, setUpdateProductStatus] = useState(null);
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [updatePayload, setUpdatePayload] = useState<IUpdatePayload>({
    productInfo: null,
    attachments: selectedAttachments,
    shopInfo: {
      shopOrigin: shop,
      shopId: shopId,
    },
  });

  const handleResourceSelection = (resource: object) => {
    setResourcePickerOpen(false);
    setUpdatePayload({ ...updatePayload, productInfo: resource });
  };

  const handleSuccessAssetModalConfirm = () => {
    setSuccessAssetModalActive(false);
    setResourcePickerOpen(true);
  };

  const handleSuccessAssetModalClose = () => {
    setSuccessAssetModalActive(false);
    setBrandfolderOnCancel();
  };

  const parseError = (errors) => {
    const jsonString = errors.replace(/'/g, '"');
    const jsonObj = JSON.parse(jsonString);
    const error = jsonObj.errors.image[0];

    return error;
  };

  const handleSuccessPayloadModalConfirm = () => {
    const data = updatePayload;
    setSuccessPayloadModalActive(false);
    setUpdateProductConfirmActive(true);

    fetch(`${environment.contentSyncFetchUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then((json) => {
        setUpdateProductStatus(json);
        resolveUpdate(json);
      });
    });
  };

  const resolveUpdate = (json) => {
    let status = 'success';
    json.results.map((result) => {
      if (result.error) {
        status = 'error';
      }
    });
    status === 'success' ? setUpdateSuccess(true) : setUpdateError(true);
  };

  const handleSuccessPayloadModalClose = () => {
    setSuccessPayloadModalActive(false);
    setBrandfolderOnCancel();
  };

  const handleResourcePickerClose = () => {
    setResourcePickerOpen(false);
    if (!updatePayload.productInfo || !updatePayload.attachments) {
      setBrandfolderOnCancel();
    }
  };

  const handleProductConfirm = () => {
    setUpdateProductConfirmActive(false);
    dispatch(updateProductCanceled());
  };

  const handleNewBrandfolder = () => {
    setUpdateProductConfirmActive(false);
    dispatch(updateProductCanceled());
  };

  const setBrandfolderOnCancel = () => {
    dispatch(updateProductCanceled());
  };

  //TODO: Refactor updateProductComponent : abstract polaris components within this component
  return (
    <section className="update-product-container">
      {updatePayload.attachments?.length && (
        <>
          <SuccessAssetModal
            open={successAssetModalActive}
            title={t(SHOPIFY_FILES_READY_FOR_UPLOAD_KEY)}
            onClose={handleSuccessAssetModalClose}
            primaryAction={{
              content: t(SHOPIFY_SELECT_PRODUCTS_KEY),
              onAction: handleSuccessAssetModalConfirm,
            }}
            secondaryActions={[
              { content: t(SHOPIFY_CANCEL_UPLOAD_KEY), onAction: handleSuccessAssetModalClose },
            ]}
          >
            <SuccessAssetModal.Section>
              <Subheading>{t(SHOPIFY_FILENAME_KEY)}</Subheading>
            </SuccessAssetModal.Section>
            <SuccessAssetModal.Section>
              {updatePayload.attachments?.map((attachment) => (
                <SuccessAssetModal.Section key={attachment.id}>
                  <p className="bf-primary-dark text-sm">{attachment.name}</p>
                  <Thumbnail
                    source={attachment.thumbnailUrl}
                    alt={`Your Brandfolder file ${attachment.name}`}
                    size="large"
                  />
                </SuccessAssetModal.Section>
              ))}
            </SuccessAssetModal.Section>
          </SuccessAssetModal>
          <ResourcePicker
            resourceType="Product"
            open={resourcePickerOpen}
            onCancel={handleResourcePickerClose}
            onSelection={(resource) => handleResourceSelection(resource)}
          ></ResourcePicker>
        </>
      )}
      {updatePayload.productInfo && updatePayload.attachments?.length && (
        <SuccessPayloadModal
          open={successPayloadModalActive}
          title={t(SHOPIFY_CONFIRM_UPLOAD_KEY)}
          onClose={handleSuccessPayloadModalClose}
          primaryAction={{
            content: t(SHOPIFY_CONFIRM_KEY),
            onAction: handleSuccessPayloadModalConfirm,
          }}
          secondaryActions={[
            { content: t(SHOPIFY_CANCEL_UPLOAD_KEY), onAction: handleSuccessPayloadModalClose },
          ]}
        >
          <SuccessPayloadModal.Section>
            <Subheading>{t(SHOPIFY_UPLOADING_FILE_KEY)}</Subheading>
            <SuccessPayloadModal.Section>
              {updatePayload.attachments?.map((attachment) => (
                <SuccessPayloadModal.Section key={attachment.id}>
                  <p className="bf-primary-dark text-sm">{attachment.name}</p>
                  <Thumbnail
                    source={attachment.thumbnailUrl}
                    alt={`Your Brandfolder file ${attachment.name}`}
                    size="large"
                  />
                </SuccessPayloadModal.Section>
              ))}
            </SuccessPayloadModal.Section>
          </SuccessPayloadModal.Section>
          <SuccessPayloadModal.Section>
            <Subheading>{t(SHOPIFY_TO_PRODUCT_KEY)}</Subheading>
            <p>{updatePayload.productInfo?.selection[0].title}</p>
          </SuccessPayloadModal.Section>
        </SuccessPayloadModal>
      )}
      {updateProductConfirmActive && (
        <div>
          {updateSuccess || updateError ? (
            <UpdateProductConfirmModal
              open={updateProductConfirmActive}
              title={
                updateError
                  ? t(SHOPIFY_ERRORS_UPLOADING_SOME_OF_YOUR_ASSETS_KEY)
                  : t(SHOPIFY_ASSETS_HAVE_BEEN_UPLOADED_KEY)
              }
              onClose={handleProductConfirm}
              primaryAction={{
                content: t(SHOPIFY_UPLOAD_FILE_FROM_CURRENT_BRANDFOLDER_KEY),
                onAction: handleProductConfirm,
              }}
              secondaryActions={[
                { content: t(SHOPIFY_CHOOSE_NEW_BRANDFOLDER_KEY), onAction: handleNewBrandfolder },
              ]}
            >
              <UpdateProductConfirmModal.Section>
                <Subheading>{t(SHOPIFY_FILES_KEY)}</Subheading>
                <SuccessPayloadModal.Section>
                  {updatePayload.attachments?.map((attachment, i) => (
                    <SuccessPayloadModal.Section key={attachment.id}>
                      <h5 className="bf-primary-dark text-sm">{attachment.name}</h5>
                      <Thumbnail
                        source={attachment.thumbnailUrl}
                        alt={`Your Brandfolder file ${attachment.name}`}
                        size="large"
                      />
                      {updateProductStatus && (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <p className="bf-primary-dark text-sm">
                            {updateProductStatus.results[i].error
                              ? `${t(SHOPIFY_ERROR_KEY)}${parseError(
                                  updateProductStatus.results[i].error,
                                )}`
                              : `${t(SHOPIFY_SUCCESS_KEY)}${t(
                                  SHOPIFY_YOUR_FILE_HAS_BEEN_SUCCESSFULLY_UPLOADED_KEY,
                                )}`}
                          </p>
                        </span>
                      )}
                    </SuccessPayloadModal.Section>
                  ))}
                </SuccessPayloadModal.Section>
              </UpdateProductConfirmModal.Section>
              <UpdateProductConfirmModal.Section>
                <TextContainer>
                  <Subheading>{t(SHOPIFY_TO_PRODUCT_KEY)}</Subheading>
                  <p>{updatePayload.productInfo.selection[0]?.title}</p>
                </TextContainer>
              </UpdateProductConfirmModal.Section>
            </UpdateProductConfirmModal>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </section>
  );
};

export default UpdateProduct;
