import {
  SHOPIFY_BACKEND_API_TOKEN,
  ShopifyBackendApi,
} from './shopify-backend-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import { BFStorage } from '@integration-frontends/core/storage';

export const customStorage: BFStorage = {
  async getItem(key: string): Promise<any> {
    const shopifyBackendApi: ShopifyBackendApi = DI_CONTAINER.get(SHOPIFY_BACKEND_API_TOKEN);
    return shopifyBackendApi.getAppStorageItem(key);
  },
  async removeItem(key: string): Promise<any> {
    const shopifyBackendApi: ShopifyBackendApi = DI_CONTAINER.get(SHOPIFY_BACKEND_API_TOKEN);
    return shopifyBackendApi.setAppStorageItem(key, null);
  },
  async setItem(key: string, item: string): Promise<any> {
    const shopifyBackendApi: ShopifyBackendApi = DI_CONTAINER.get(SHOPIFY_BACKEND_API_TOKEN);
    return shopifyBackendApi.setAppStorageItem(key, item);
  },
};
