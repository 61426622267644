import { Environment } from '@integration-frontends/apps/common/utils/environment-types';

export const environment = {
  production: true,
  bfBaseUrl: 'https://brandfolder.com/api/v1/contentsync/shopify/bf-api-proxy',
  shopifyBackendBaseUrl: 'https://brandfolder.com/api/v1/contentsync/shopify',
  contentSyncFetchUrl: 'https://brandfolder.com/api/v1/contentsync/shopify/product_image',
  contentSyncUrlShopParam: 'https://brandfolder.com/api/v1/contentsync/shopify/install?shop=',
  shopifyApiKey: '4e7485ada80040cec28293d1a9a8b3b2',
  segmentWriteKey: 'mXNgAITR8qQXLuSX6OlUYlzkdn5wFtMD',
  environment: Environment.Production,
};
