import { all, call, delay, put } from 'typed-redux-saga';
import { removeFileProcessing } from '../../../actions';
import { assetEntityActions, attachmentEntityActions } from '../../../../../common';
import {
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

const MAX_RETRIES = 6;

export function* handleProcessFile(assetId: string) {
  yield call(handleFileProcessing, assetId);
}

function* handleFileProcessing(assetId: string | undefined) {
  let retryCount = 0;

  const POLLING_INTERVALS = [2000, 4000, 8000, 16000, 32000, 64000];

  while (retryCount < MAX_RETRIES) {
    const attachments = yield call(fetchAssetAttachments, assetId);
    if (attachments[0]?.isProcessing === false) {
      const asset = yield call(fetchAsset, assetId);
      yield put(removeFileProcessing({ assetIds: [assetId] }));
      yield put(assetEntityActions.assetUpdated({ id: asset.id, changes: { ...asset } }));
      yield put(attachmentEntityActions.attachmentDetailsReceived(attachments));
      break;
    }

    const pollingInterval = POLLING_INTERVALS[retryCount];

    yield delay(pollingInterval);
    retryCount++;
  }
}

function fetchAssetAttachments(assetId: string) {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
  return attachmentRepo.listAssetAttachments(assetId);
}

function fetchAsset(assetId: string) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  return assetRepo.fetchAsset(assetId);
}
