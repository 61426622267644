import {
  AttachmentActionContainerBaseProps,
  IAttachmentActionProvider,
} from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-model';
import { UploadActionContainer } from '../ui/attachment-selector/upload-action-container';

export class AttachmentActionsProviderShopifyStrategy implements IAttachmentActionProvider {
  getActionContainers(): React.FunctionComponent<AttachmentActionContainerBaseProps>[] {
    return [UploadActionContainer];
  }
}
