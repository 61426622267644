import { call, put, takeEvery } from 'typed-redux-saga';
import { init } from '@integration-frontends/common/app';
import { getAuthTokenShopifyStrategy } from '../common/auth/get-auth-token-shopify-strategy';
import { clear } from '@integration-frontends/common/auth/core/application';
import { push } from 'redux-first-history';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import {
  DI_CONTAINER,
  getObservabilityService,
  integrationFrontendsDD,
} from '@integration-frontends/core';
import { getPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence/get-persisted-route';
import { environment } from '../../environments/environment';

function* handleInit() {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
  const token = yield call(getAuthTokenShopifyStrategy);
  const authed = yield call(authService.isAuthenticated, token);
  const identity = yield call(identityStore.get);
  const service = 'integration-shopify';

  if (!authed) {
    yield put(clear());
    yield put(push('/welcome'));
  } else {
    yield call(getObservabilityService().initObservability, {
      enableSessionRecordings: true,
      env: environment?.environment,
      datadogCredentials: integrationFrontendsDD,
      service,
    });
    yield call(getObservabilityService().sendIdentity, identity, environment?.environment);

    const savedRoute: Location = yield call(getPersistedRoute);
    if (savedRoute) {
      console.log('Retrieved persisted route : ' + savedRoute.pathname);
      yield put(push(savedRoute));
    } else yield put(push('/select-attachment'));
  }
}

export function* initWorkflow() {
  yield takeEvery(init, handleInit);
}
