import { push } from 'redux-first-history';
import { put, takeEvery } from 'redux-saga/effects';
import { updateProductCanceled } from '../components/update-product/state/actions';
import { deselectAllAttachments } from '@integration-frontends/integration/core/application';

const handler = function* () {
  yield put(push(`/select-attachment`));
  yield put(deselectAllAttachments());
};

export function* updateProductCanceledWorkflow() {
  yield takeEvery(updateProductCanceled, handler);
}
