import { multiSelectAttachmentEnded } from '@integration-frontends/integration/core/application';
import { push } from 'redux-first-history';
import { put, takeEvery } from 'redux-saga/effects';

const handler = function* (action: ReturnType<typeof multiSelectAttachmentEnded>) {
  yield put(push(`/update-product`));
};

export function* multiSelectAttachmentEndedWorkflow() {
  yield takeEvery(multiSelectAttachmentEnded, handler);
}
