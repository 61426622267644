import { push } from 'redux-first-history';
import { put, takeEvery } from 'redux-saga/effects';
import { updateProductInit } from '../components/update-product';

const handler = function* () {
  yield put(push(`/update-product`));
};

export function* updateProductWorkflow() {
  yield takeEvery(updateProductInit, handler);
}
