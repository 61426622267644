import { SetApiKeyForm } from '../../../../../libs/common/auth/ui/src/lib/set-api-key/set-api-key-form';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shopifyLogin } from '../actions';
import { selectError } from '@integration-frontends/common/auth/core/application';

export function ShopifySetApiKey() {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const [apiKey, setApiKey] = useState(null);

  const onSubmit = useCallback(() => {
    dispatch(shopifyLogin({ apiKey }));
  }, [apiKey]);

  const onApiKeyChange = useCallback((apiKey: string) => {
    setApiKey(apiKey);
  }, []);

  return (
    <SetApiKeyForm
      apiKey={apiKey}
      onApiKeyChange={onApiKeyChange}
      error={error}
      onSubmit={onSubmit}
    />
  );
}
