import { IconUploadAssets, IconUploadAssetsDisabled } from '@integration-frontends/common/ui';
import {
  attachmentSelected,
  multiSelectAttachmentEnded,
} from '@integration-frontends/integration/core/application';
import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import {
  INTEGRATION_COMMON_NAMESPACE,
  UPLOAD_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export function UploadActionContainer({
  selectedAttachments,
  isMultiSelect,
  render,
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const id = 'UPLOAD';
  const label = t(UPLOAD_KEY);
  const [success, setSuccess] = useState(false);
  const [attachment] = selectedAttachments;
  const IconUpload = IconUploadAssets;
  const { isSupported } = useMediaTypeSupport();

  const uploadSingleAttachment = () => {
    dispatch(attachmentSelected({ attachment: attachment }));
    dispatch(multiSelectAttachmentEnded({ attachments: selectedAttachments }));
    setSuccess(true);
  };

  const endAttachmentSelection = () => {
    dispatch(multiSelectAttachmentEnded({ attachments: selectedAttachments }));
    setSuccess(true);
  };

  const onClick = () => {
    isMultiSelect ? endAttachmentSelection() : uploadSingleAttachment();
  };

  const enabled = isSupported(attachment?.mediaType);

  return render({
    id,
    Icon: enabled ? IconUpload : IconUploadAssetsDisabled,
    label,
    loading: false,
    success,
    enabled,
    onClick,
    ariaLabel: t(UPLOAD_KEY),
  });
}
