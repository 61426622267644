import { all, call, fork } from 'redux-saga/effects';

import { authWorkflow } from './auth-workflow';
import { initWorkflow } from './init-workflow';
import { multiSelectAttachmentEndedWorkflow } from './multi-select-attachment-workflow';
import { updateProductCanceledWorkflow } from './update-product-canceled-workflow';
import { updateProductWorkflow } from './update-product-workflow';
import { createPersistRouteEffect } from '@integration-frontends/apps/common/routing/route-persistence/persist-route-effect';
import { createPageLoadErrorRedirectEffect } from '../../../../common/src/errors';
import { getObservabilityService } from '@integration-frontends/core';

export function* rootWorkflow() {
  try {
    yield all([
      fork(authWorkflow),
      fork(initWorkflow),
      fork(multiSelectAttachmentEndedWorkflow),
      fork(createPersistRouteEffect([/\/select-attachment/])),
      fork(updateProductCanceledWorkflow),
      fork(updateProductWorkflow),
      fork(createPageLoadErrorRedirectEffect('/select-attachment')),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
  }
}
