import 'reflect-metadata';
import './init';
import { RootContainer } from '@integration-frontends/core/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import 'libs/common/ui/src/lib/styles/tailwind.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './app/app';
import i18next from './i18n';

ReactDOM.render(
  <React.StrictMode>
    <RootContainer>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </RootContainer>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);
