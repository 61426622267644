export interface ShopifyParams {
  shop: string;
  shopId: string;
  host: string;
  isAuthed: boolean;
}
export function extractShopifyParams(): ShopifyParams {
  const params = new URLSearchParams(window.location.search);
  const shop = params.get('shop');
  const shopId = params.get('shopify_dest_id');
  const host = params.get('host');
  const isAuthed = params.get('is_authed') === 'true';

  return { shop, shopId, host, isAuthed };
}
