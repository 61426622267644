import { i18n, Messages } from '@lingui/core';

export type FullyQualifiedLocales =
  | 'bg'
  | 'cs'
  | 'fi'
  | 'fr'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'hu'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nb'
  | 'nl'
  | 'pl'
  | 'pt-BR'
  | 'ro'
  | 'ru'
  | 'sv'
  | 'th'
  | 'tr'
  | 'vi'
  | 'zh-Hans';

const loadAndActivate = (locale: FullyQualifiedLocales, messages: Messages): void => {
  i18n.load(locale, messages);
  i18n.activate(locale);
};

loadAndActivate('en', {});

export const dynamicActivate: (locale?: FullyQualifiedLocales) => Promise<void> = async (
  locale = 'en',
) => {
  const { messages } = await import(`./locales/${locale}/messages.js`);

  loadAndActivate(locale, messages);
};
