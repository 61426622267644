import { createReducer } from '@reduxjs/toolkit';
import { updateProductInit, updateProductCanceled, updateProductSuccess } from './actions';

export interface UpdateProductState {
  loading: boolean;
}

const initialState: UpdateProductState = {
  loading: false,
};

export const UPDATE_PRODUCT_REDUCER = createReducer(initialState, (builder) => {
  builder
    .addCase(updateProductInit, (state) => {
      state.loading = true;
    })
    .addCase(updateProductCanceled, (state) => {
      state.loading = true;
    })
    .addCase(updateProductSuccess, (state) => {
      state.loading = false;
    });
});
