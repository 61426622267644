import { inject, injectable } from 'inversify';
import {
  AuthError,
  CredentialsInvalidError,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';
import { SHOPIFY_BACKEND_API_TOKEN, ShopifyBackendApi } from '../shopify-backend-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  isError,
  UserDto,
} from '@integration-frontends/common/brandfolder-api';

export interface ShopifyCredentials {
  sessionToken: string;
  apiKey: string;
}

@injectable()
export class AuthenticationServiceShopifyStrategy
  implements IAuthenticateService<ShopifyCredentials>
{
  @inject(BRANDFOLDER_API_TOKEN)
  private brandfolderApi: BrandfolderApi;
  private shopifyBackendApi: ShopifyBackendApi;

  constructor() {
    this.shopifyBackendApi = DI_CONTAINER.get(SHOPIFY_BACKEND_API_TOKEN);
  }

  authenticate({ apiKey, sessionToken }: ShopifyCredentials): Promise<Identity | AuthError> {
    return this.shopifyBackendApi.setApiKey(apiKey).then(() => {
      return this.getCurrentUserDto(sessionToken).then((user) => {
        return user
          ? {
              token: sessionToken,
              userId: user.id,
              firstName: user.attributes.first_name,
              lastName: user.attributes.last_name,
              email: user.attributes.email,
            }
          : new CredentialsInvalidError();
      });
    });
  }

  logout = (): Promise<void> => {
    return this.shopifyBackendApi.setApiKey(null);
  };

  isAuthenticated = (token: string): Promise<boolean> => {
    return this.getCurrentUserDto(token).then(Boolean);
  };

  private getCurrentUserDto = async function (sessionToken: string): Promise<UserDto> {
    return this.brandfolderApi
      .whoAmI(sessionToken)
      .then((res) => (isError(res) ? null : res.data))
      .catch(() => null);
  };
}
