import { useAppBridge, useNavigate } from '@shopify/app-bridge-react';
import { useEffect } from 'react';

export function EscapeIframeAndRedirect() {
  const app = useAppBridge();
  const search = window.location.search;
  const navigate = useNavigate();

  useEffect(() => {
    if (!!app && !!search) {
      const params = new URLSearchParams(search);
      const redirectUri = params.get('redirect_uri');

      navigate(redirectUri);
    }
  }, [app, search]);

  return null;
}
