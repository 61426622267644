export const SHOPIFY_SUCCESS_KEY = 'success';
export const SHOPIFY_YOUR_FILE_HAS_BEEN_SUCCESSFULLY_UPLOADED_KEY =
  'yourFileHasBeenSuccessfullyUploadedToShopify';
export const SHOPIFY_ERROR_KEY = 'error';
export const SHOPIFY_FILES_KEY = 'files';
export const SHOPIFY_FILES_READY_FOR_UPLOAD_KEY = 'filesReadyForUpload';
export const SHOPIFY_SELECT_PRODUCTS_KEY = 'selectProducts';
export const SHOPIFY_CANCEL_UPLOAD_KEY = 'cancelUpload';
export const SHOPIFY_FILENAME_KEY = 'filename';
export const SHOPIFY_CONFIRM_UPLOAD_KEY = 'confirmUpload';
export const SHOPIFY_CONFIRM_KEY = 'confirm';
export const SHOPIFY_UPLOADING_FILE_KEY = 'uploadingFile';
export const SHOPIFY_TO_PRODUCT_KEY = 'toProduct';
export const SHOPIFY_UPLOAD_FILE_FROM_CURRENT_BRANDFOLDER_KEY = 'uploadFileFromCurrentBrandfolder';
export const SHOPIFY_CHOOSE_NEW_BRANDFOLDER_KEY = 'chooseNewBrandfolder';
export const SHOPIFY_ERRORS_UPLOADING_SOME_OF_YOUR_ASSETS_KEY = 'errorsUploadingSomeOfYourAssets';
export const SHOPIFY_ASSETS_HAVE_BEEN_UPLOADED_KEY = 'assetsHaveBeenUploadedToShopify';
