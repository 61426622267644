import { IGetAuthToken } from '@integration-frontends/integration/infrastructure/isomorphic';
import { getSessionToken } from '@shopify/app-bridge-utils';

export const SHOPIFY_SESSION_TOKEN_PROVIDER = 'SHOPIFY_SESSION_TOKEN_PROVIDER';

let shopifyApp;

export const setShopifyAppState = (app) => {
  shopifyApp = app;
};

export const getAuthTokenShopifyStrategy: IGetAuthToken = async () => {
  if (shopifyApp) {
    return getSessionToken(shopifyApp);
  }

  return null;
};
