import {
  loginSuccess,
  loginThunk,
  logout,
} from '@integration-frontends/common/auth/core/application';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import { push } from 'redux-first-history';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { shopifyLogin } from '../actions';
import { SHOPIFY_SESSION_TOKEN_PROVIDER } from '../common/auth/get-auth-token-shopify-strategy';
import {
  AuthenticationServiceShopifyStrategy,
  ShopifyCredentials,
} from '../common/auth/authentication-service-shopify-strategy';
import { AUTHENTICATE_SERVICE_TOKEN } from '@integration-frontends/common/auth/core/model';
import { environment } from '../../environments/environment';

function* login(action: ReturnType<typeof shopifyLogin>) {
  const sessionTokenProvider = DI_CONTAINER.get(SHOPIFY_SESSION_TOKEN_PROVIDER);
  const token = yield call(sessionTokenProvider);

  yield put(
    loginThunk<ShopifyCredentials>({ sessionToken: token, apiKey: action.payload.apiKey }) as any,
  );
}

function* onLoginSuccess(action: ReturnType<typeof loginSuccess>) {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const { identity } = action.payload;
  yield call(identityStore.set, action.payload.identity);
  yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
  yield put(push('/select-attachment'));
}

function* onLogout() {
  yield call(getObservabilityService().stopSessionRecording);
  const authService: AuthenticationServiceShopifyStrategy = DI_CONTAINER.get(
    AUTHENTICATE_SERVICE_TOKEN,
  );
  yield call(authService.logout);
  yield put(push('/welcome'));
}

export function* authWorkflow() {
  yield all([
    takeEvery(shopifyLogin.type, login),
    takeEvery(loginSuccess.type, onLoginSuccess),
    takeEvery(logout.type, onLogout),
  ]);
}
