import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import { IntegrationUiModule } from '@integration-frontends/integration/ui';
import { all, fork } from 'redux-saga/effects';
import { rootWorkflow } from './workflows/index';
import { appRootReducer } from '@integration-frontends/common/app';
import {
  notificationEffects,
  notificationsRootReducer,
} from '@integration-frontends/common/notifications';
import { createAppStore } from '@integration-frontends/apps/common/store/create-app-store';
import { createBrowserHistory } from 'history';

const ENVIRONMENT = 'development';

const rootSaga = function* () {
  yield all([
    fork(rootWorkflow),
    fork(IntegrationUiModule.getEffects()),
    fork(notificationEffects),
  ]);
};

export const { history, store } = createAppStore({
  environment: ENVIRONMENT,
  reducers: {
    ...notificationsRootReducer,
    ...appRootReducer,
    ...AuthUiModule.getRootReducer(),
    ...IntegrationUiModule.getRootReducer(),
  },
  rootSaga,
  history: createBrowserHistory(),
});
